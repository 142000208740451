import debounce from '../utils/debounce';
import Swiper, { Lazy } from 'swiper';

class PortfolioScroll {
  constructor(el) {
    /* eslint-disable */
    this.projects = projects;
    /* eslint-enable */

    this.container = el;
    // this.lists = el.querySelectorAll('.js-projects');

    this.count = this.projects.length;

    this.projectTitle = el.querySelector('.project__h__link');
    this.projectLink = el.querySelector('.project');
    this.projectImage = el.querySelector('.project__asset--img');
    this.projectVideo = el.querySelector('.project__asset--video');
    this.projectText = el.querySelector('.project__desc');
    this.projectYear = el.querySelector('.project__year');
    this.projectCursor = el.querySelector('.project__cursor');
    this.mobileCarousel = el.querySelector('.js-project-carousel');

    if (window.innerWidth < 800) {
      el.classList.add('carousel');
      el.classList.remove('hoverscroll');
      this.swipers = el.querySelectorAll('.swiper');
      this.buildCarousel(() => {
        this.initSwiper(this.swipers);
      });
    } else {
      this.preloadImages(this.projects);

      el.classList.add('hoverscroll');
      this.hoverScroll = debounce(this.hoverScroll.bind(this, el), 1);
      this.container.addEventListener('mousemove', this.hoverScroll);
    }
  }

  preloadImages(projects) {
    for (const i in projects) {
      const currentImg = projects[i];
      const srcset = `/static/img/${currentImg.asset.name}-640.jpg 640w, /static/img/${currentImg.asset.name}-768.jpg 768w, /static/img/${currentImg.asset.name}-1024.jpg 1024w, /static/img/${currentImg.asset.name}-1440.jpg 1440w, /static/img/${currentImg.asset.name}-1660.jpg 1660w, /static/img/${currentImg.asset.name}-2400.jpg 2400w`;
      const img = new Image();
      img.src = `/static/img/${currentImg.asset.name}-640.jpg`;
      img.srcset = srcset;
      
      if (i == 0) {
        img.onload = function() {
          document.body.classList.add('loaded');
        };
      }
    }
  }

  buildCarousel(callback) {
    let slides = '';
    for (let project in this.projects) {
      project = this.projects[project];

      const srcset = `/static/img/${project.asset.name}-640.jpg 640w, /static/img/${project.asset.name}-768.jpg 768w, /static/img/${project.asset.name}-1024.jpg 1024w, /static/img/${project.asset.name}-1440.jpg 1440w, /static/img/${project.asset.name}-1660.jpg 1660w, /static/img/${project.asset.name}-2400.jpg 2400w`;
      const slide = `
        <div class="swiper-slide">
          <a class="project" href="${project.link}" target="_blank">
            <div class="project__asset__mod">
              <img data-src="/static/img/${project.asset.name}-640.jpg" data-srcset="${srcset}" alt="${project.asset.alt}" class="project__asset project__asset--img swiper-lazy">
              <div class="swiper-lazy-preloader"></div>
            </div>
            <div class="project__text-mod">
              <h3 class="project__h"><span class="project__h__link">${project.name}</span></h3>
              <p class="project__desc">${project.text}</p>
              <p class="project__year">${project.year}</p>
            </div>
          </a>
          
        </div>
      `;
      slides = slides + slide;
    }
    this.mobileCarousel.innerHTML = `
      
      <div class="swiper-wrapper">
        ${slides}
      </div>
    `;

    callback();
  }

  initSwiper(target) {
    for (const i in target) {
      new Swiper(target[i], {
        preloadImages: true,
        loop: true,
        lazy: true,
        modules: [Lazy],
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      });
    }

    // debugger;
  }

  hoverScroll(el, event) {
    const x = event.clientX;
    const y = event.clientY;
    const width = el.clientWidth;
    const offset = el.offsetLeft;
    const percentage = (x - offset) / width;
    const mapPercentage = percentage < 0 ? 0 : percentage > 1 ? 1 : percentage;
    const currentProject = this.projects[Math.floor(this.count * mapPercentage)];

    this.projectTitle.innerHTML = currentProject.name;
    this.projectText.innerHTML = currentProject.text;
    this.projectYear.innerHTML = currentProject.year;
    this.projectLink.href = currentProject.link;
    this.projectImage.src = `/static/img/${currentProject.asset['name']}-640.jpg`;
    this.projectImage.srcset = `/static/img/${currentProject.asset.name}-640.jpg 640w, /static/img/${currentProject.asset.name}-768.jpg 768w, /static/img/${currentProject.asset.name}-1024.jpg 1024w, /static/img/${currentProject.asset.name}-1440.jpg 1440w, /static/img/${currentProject.asset.name}-1660.jpg 1660w, /static/img/${currentProject.asset.name}-2400.jpg 2400w`;

    this.projectImage.alt = currentProject.asset['alt'];

    if (currentProject.asset['video']) {
      /* eslint-disable */
      const relativeSrc = this.projectVideo.src.replace(/.*\/\/[^\/]*/, '');
      /* eslint-enable */
      const videoSrc = `/static/img/${currentProject.asset['name']}.mp4`;

      this.projectLink.classList.add('show-video');

      if (relativeSrc !== videoSrc) {
        this.projectVideo.src = videoSrc;
      }
    } else {
      this.projectLink.classList.remove('show-video');
    }

    el.style.setProperty('--mouse-x', `${x}px`);
    el.style.setProperty('--mouse-y', `${y}px`);
  }
}

export default PortfolioScroll;
