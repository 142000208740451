/* eslint-disable */
import 'modernizr';
/* eslint-enable */

import { onDocumentReady } from './utils';

// Components
import Links from './components/links';
import ToggleGrid from './components/toggle-grid';
import Portfolio from './components/portfolio-scroll';

onDocumentReady(() => {
  new Links();
  new ToggleGrid();

  if (document.querySelector('.js-hang-punc')) {
    import(/* webpackChunkName: "hang-punctuation" */ './components/hang-punctuation').then(
      module => {
        const HangPunctuation = module.default;
        new HangPunctuation();
      },
    );
  }

  const porfolioEls = document.querySelectorAll('.js-projects');
  if (porfolioEls.length > 0) {
    Array.from(porfolioEls).map(el => new Portfolio(el));
  }
});
