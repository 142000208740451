class ToggleGrid {
  constructor() {
    this.initialize();
    this.themes = ['dark', 'purple'];
  }

  initialize() {
    document.onkeydown = e => {
      // Press 0 to toggle the grid
      if (e.key === '0') {
        document.body.classList.toggle('show-grid');
      }

      if (e.key === '1') {
        const currentTheme = document.body.getAttribute('data-theme') || this.themes[0];
        const nextIndex = 1 + this.themes.indexOf(currentTheme);
        const nextTheme = nextIndex < this.themes.length ? this.themes[nextIndex] : this.themes[0];
        document.body.setAttribute('data-theme', nextTheme);
      }
    };
  }
}

export default ToggleGrid;
